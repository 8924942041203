<template>
  <!-- Start home content -->
  <div class="content">
    <ImportDocument v-if="modal.importDocument" :close="closeImportDocumentModal" :addCreatedTender="addCreatedTender" />
    <ImportText v-if="modal.importText" :close="closeImportTextModal" :addCreatedTender="addCreatedTender" />
    <DeleteDocument v-if="modal.deleteTender" :toDelete="toDelete" :confirmDeleteTender="(id) => confirmDeleteTender(id)" :close="closeDeleteTenderModal" />

    <div class="top">
      <img src="@/assets/images/documents.svg" alt="documents">
      <h2>Liste de documents</h2>
    </div>
    <div class="actions">
      <div>
        <input
          type="text"
          v-model="filters.query"
          :class="({ 'search-active': filters.query })"
          class="search"
          placeholder="Rechercher un document">
        <span @click="clearQuery" class="search-cross" v-if="filters.query">&#10005;</span>
      </div>
      <div class="actions__btns">
        <button class="btn btn--blue" @click="modal.importText = true">
          <img class="btn__icon" src="@/assets/images/icons/i-plus.svg" alt="plus icon">
          Importer un texte libre
        </button>
        <button class="btn btn--blue" @click="modal.importDocument = true">
          <img class="btn__icon" src="@/assets/images/icons/i-plus.svg" alt="plus icon">
          Importer un document
        </button>
      </div>
    </div>

    <div class="tab">
      <div class="tab__status">
        <ul>
          <li
            @click="activeFilter('PROGRESS')"
            :class="({ 'tab__status--active': filters['PROGRESS'] && !areAllChecked })"
            class="tab__status--current">En cours ({{ tendersCount['PROGRESS'] || 0 }})</li>
          <li
            @click="activeFilter('WON')"
            :class="({ 'tab__status--active': filters['WON'] && !areAllChecked })"
            class="tab__status--won">Gagnés ({{ tendersCount['WON'] || 0 }})</li>
          <li
            @click="activeFilter('LOST')"
            :class="({ 'tab__status--active': filters['LOST'] && !areAllChecked })"
            class="tab__status--lost">Perdus ({{ tendersCount['LOST'] || 0 }})</li>
          <li
            @click="activeFilter('all')"
            :class="({ 'tab__status--active': areAllChecked })" >Tous ({{ totalTenders }})</li>
        </ul>
      </div>
    </div>

    <div class="datatable">
      <div class="datatable__head">
        <p class="f-2">Statut</p>
        <p class="f-9">Nom du document</p>
        <p class="f-6">Progression</p>
        <p class="f-4">Date de création</p>
        <!-- <p class="f-2">MAJ</p> -->
        <p class="f-8">Collaborateur</p>
        <p class="f-9"></p>
      </div>
      <div v-if="tendersLoading" class="download loading"><span class="lds-dual-ring"></span></div>
      <div class="datatable__body">
        <div v-for="tender in tenders" :key="tender.id">
          <div v-if="tender.new === 0" :class="{ 'datatable__row--open': statusChange[tender.id]}" class="row">
            <div @click="toggleStatusChange(tender.id)" :class="{ 'datatable__status--lost': tender.status === 'LOST', 'datatable__status--won': tender.status === 'WON' }" class="f-1 datatable__status">
              <div class="datatable__changeStatus">
                <div @click="resetState(tender)" >
                  <img src="@/assets/images/icons/i-clock-violet.svg" alt="clock-icon">
                  <p>En cours</p>
                </div>
                <div @click="winTender(tender)" class="datatable__changeStatus--won">
                  <img src="@/assets/images/icons/i-check-round-green.svg" alt="clock-icon">
                  <p>Gagné</p>
                </div>
                <div @click="loseTender(tender)" class="datatable__changeStatus--lost">
                  <img src="@/assets/images/icons/i-times-round-red.svg" alt="clock-icon">
                  <p>Perdu</p>
                </div>
              </div>
            </div>
            <p class="f-1"></p>
            <p class="f-9 datatable__title">
              <router-link class="datatable__name" :to="goToList(tender)">{{tender.name}}</router-link>
            </p>
            <p class="f-6">{{parseFloat(tender.progress).toFixed(2)}}% ({{tender.done}}/{{tender.total}})</p>
            <p class="f-4 datatable__hiddenWhenOpen">{{ tender.created_at.split(' ')[0] }}</p>
            <!-- <p class="f-2 datatable__hiddenWhenOpen">07/12</p> -->
            <p class="f-8 datatable__hiddenWhenOpen">{{tender.username}}</p>
            <div class="f-9 datatable__actions">
              <router-link class="btn btn--blue" :to="treatTender(tender)">Traiter</router-link>
              <button
                @click="exportTender(tender)"
                class="btn btn--blue btn--export" >
                Exporter
              </button>
              <button @click="deleteTender(tender.id)" class="btn--icon" title="Supprimer">
                <img src="@/assets/images/icons/i-trash.svg" alt="trash icon">
              </button>
              <button
                title="Télécharger l'AO initial"
                @click="downloadTender(tender)"
                class="btn--icon" >
                <img src="@/assets/images/icons/i-download.svg" alt="download icon">
              </button>
            </div>
          </div>
          <div v-else-if="tender.name.toLowerCase().includes(filters.query.toLowerCase())" class="download" >
            <h4>Importation du document « {{tender.name}} »</h4>
            <div class="download__container">
              <div class="download__section__bar">
                <p>{{eventSources[tender.id]}}%</p>
                <div class="download__bar">
                  <div class="download__progress" :style="{ width: eventSources[tender.id]+'%'}"></div>
                </div>
                <button @click="deleteTender(tender.id)" >
                  <img src="@/assets/images/icons/i-times-black.svg" alt="close btn">
                </button>
              </div>
              <router-link class="btn btn--blue" :to="treatTender(tender)">Traiter les lignes déja remplies</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <p class="pagination__results" v-if="tendersCount">
          Résultats {{ (page - 1) * resultsPerPage }} - {{ (page - 1) * resultsPerPage + tenders.length }} sur {{ tendersCount[getActiveFilter()] || 0 }}
        </p>
        <div class="pagination__bottom">
          <div class="pagination__container">
            <button
              v-if="this.page > 1"
              @click="setPage(page - 1)" class="pagination__item">
              <img src="@/assets/images/icons/i-chevron-left-black.svg" alt="chevron left icon">
            </button>
            <button
              v-for="pageToDisplay in pages" :key="pageToDisplay"
              @click="setPage(pageToDisplay)"
              type="button"
              :class="({ 'pagination__item--active': page === pageToDisplay })"
              class="pagination__item ">
              {{ pageToDisplay }}
            </button>
            <button
              v-if="this.page < lastPage"
              @click="setPage(page + 1)"
              class="pagination__item">
              <img src="@/assets/images/icons/i-chevron-right-black.svg" alt="chevron right icon">
            </button>
          </div>
          <div class="pagination__goto">
            <p>Aller à la page</p>
            <input
              min="1"
              :max="lastPage"
              v-model.lazy.number="page"
              @change="setPage(page)"
              type="number">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End home content -->
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';

import ImportDocument from '@/components/ImportDocument';
import ImportText from '@/components/ImportText';
import DeleteDocument from '@/components/DeleteDocument';
import {debounce} from '@/utils/index.js'

import usePaginate from '@/hooks/paginate';
import { PAGE_STORAGE_KEY } from '@/constants/storage';

export default {
  name: 'Home',
  components: { DeleteDocument, ImportText, ImportDocument },
  data: () => ({
    stats: {},
    statusChange: {},
    toDelete: {},
    eventSources: [],
  }),
  setup(props, { root }) {
    function setPage(page) {
      root.$router.push({
        query: { page },
      }).catch(() => {});

      localStorage.setItem(PAGE_STORAGE_KEY, page);
      goToPage(+page);
    }

    /** EventSources */
    let eventSources = ref({})
    function createEventSource(tenderProcessing) {
      const server = new EventSource(`${process.env.VUE_APP_API_URL}/tenders/${tenderProcessing.id}/progress`);
      server.addEventListener('tenderProcessing', (event) => {
        eventSources.value = {...eventSources.value, [tenderProcessing.id]: event.data}
        if (event.data === '100') {
          server.close()
        }
      })
      eventSources.value[tenderProcessing.id] = 0
    }

    /** End EventSources */
    const modal = ref({
      importDocument: false,
      importText: false,
      deleteTender: false,
    })

    function addCreatedTender(tender) {
      tenders.value.unshift(tender)
      createEventSource(tender)
      closeImportDocumentModal()
      closeImportTextModal()
    }

    function closeImportDocumentModal() {
      modal.value.importDocument = false
    }
    function closeImportTextModal() {
      modal.value.importText = false
    }

    function closeDeleteTenderModal() {
      modal.value.deleteTender = false
    }

    /** Filters */
    const filters = ref({
      WON: false,
      LOST: false,
      PROGRESS: true,
      query: '',
    });

    function setFiltersTo(value) {
      filters.value = {
        WON: value,
        LOST: value,
        PROGRESS: value,
        query: filters.value.query,
      };
    }

    const areAllChecked = computed(() => {
      return !Object.values(filters.value).some(filter => !filter)
    });

    function activeFilter(filter) {
      if (filter === 'all') {
        setFiltersTo(true);
      } else {
        setFiltersTo(false);
        filters.value[filter] = true;
      }
      setPage(1);
    }

    function getActiveFilter() {
      return Object
        .entries(filters.value)
        .find(([, value]) => value === true)[0];
    }
    /** End filters */

    /** Pagination */
    const resultsPerPage = 25;
    const {
      data: tenders,
      currentPage: page,
      total,
      loading: tendersLoading,
      lastPage, pages,
      goToPage, previous, next,
    } = usePaginate({
      url: `/tenders`,
      currentPage: parseInt(root.$route.query.page) || 1,
      options: filters,
      resultsPerPage,
      dataTransformer: response => {
        const { tenders } = response;

        tenders.forEach(tender => {
          if (tender.new > 0 && !(tender.id in eventSources)) {
            createEventSource(tender)
          }
        });

        return tenders;
      },
      totalPageTransformer: response => Math.ceil(response.count.reduce((acc, a) => filters.value[a['status']] ? acc + a['count'] : acc, 0) / resultsPerPage),
      totalTransformer: response => response.count
    });

    const tendersCount = computed(() => (Array.isArray(total.value) ? total.value : []).reduce((acc, value) => {
      acc[value.status] = value.count;

      return acc;
    }, {}));
    const totalTenders = computed(() => (Array.isArray(total.value) ? total.value : []).reduce((acc, { count }) => acc + count, 0));

    const debouncedSearch = debounce(() => {
      setPage(1);
    }, 700);
    // Allows refresh on input clear
    watch(() => filters.value.query, debouncedSearch);
    
    /** End pagination */

    function init() {
      const page = +root.$route.query.page || 1;
      localStorage.setItem(PAGE_STORAGE_KEY, page);
      setPage(page);
    }

    init();

    return {
      eventSources,

      addCreatedTender,

      modal,
      closeImportDocumentModal,
      closeImportTextModal,
      closeDeleteTenderModal,

      filters,
      getActiveFilter,
      areAllChecked,
      activeFilter,

      tenders,
      tendersLoading,
      tendersCount,
      totalTenders,

      page,
      pages,
      resultsPerPage,
      setPage,
      previous,
      next,
      lastPage,
    }
  },
  methods: {
    goToList(tender) {
      return {
        name: 'list',
        params: {
          tender: tender.id,
        },
      };
    },
    treatTender(tender) {
      const { id, firstdemandid } = tender;
      return {
        name: firstdemandid ? 'demand' : 'list',
        params: {
          tender: id,
          demand: firstdemandid || undefined,
        },
      };
    },
    toggleStatusChange(id) {
      this.statusChange = {...this.statusChange, [id]: !this.statusChange[id] ? true : !this.statusChange[id]}
    },
    updateStats(tender, newStatus) {
      const { status } = tender;
      this.tendersCount[status] -= 1;
      this.tendersCount[newStatus] += 1;
    },
    winTender({ status, id }) {
      if (status === 'WON') return;
      this.$axios.patch(`/tenders/${id}`, { status: 'WON' })
        .then(response => {
          this.tenders = this.tenders.map(tender => {
            if (tender.id === id) {
              this.updateStats(tender, 'WON');
              return {...tender, status: response.data.status}
            }
            return tender
          });
          this.$notify({
            text: 'Votre statut a été mis à jour. Le document a été déplacé dans "Gagnés".',
          });
        })
        .catch(() => {
          this.$notify({
            text: 'Votre statut n’a pas été mis à jour. Le document n’a pas été déplacé.',
            type: 'error',
          });
        });
    },
    loseTender({ status, id }) {
      if (status === 'LOST') return;
      this.$axios.patch(`/tenders/${id}`, { status: 'LOST' })
        .then(response => {
          this.tenders = this.tenders.map(tender => {
            if (tender.id === id) {
              this.updateStats(tender, 'LOST');
              return {...tender, status: response.data.status}
            }
            return tender
          });
          this.$notify('Votre statut a été mis à jour. Le document a été déplacé dans "Perdus".');
        })
        .catch(() => {
          this.$notify({
            text: 'Votre statut n’a pas été mis à jour. Le document n’a pas été déplacé.',
            type: 'error',
          });
        })
    },
    resetState({ status, id }) {
      if (status === null) return;
      this.$axios.patch(`/tenders/${id}`, { status: 'PROGRESS' })
        .then(response => {
          this.tenders = this.tenders.map(tender => {
            if (tender.id === id) {
              this.updateStats(tender, 'PROGRESS');
              return {...tender, status: response.data.status}
            }
            return tender
          })
          this.$notify('Votre statut a été mis à jour. Le document a été déplacé dans "En cours".');
        })
        .catch(() => {
          this.$notify({
            text: 'Votre statut n’a pas été mis à jour. Le document n’a pas été déplacé.',
            type: 'error',
          });
        });
    },
    deleteTender(id, name) {
      this.toDelete = { id, name }
      this.modal.deleteTender = true
    },
    confirmDeleteTender(id) {
      this.$axios.delete(`/tenders/${id}`)
        .then(() => {
          this.tenders = this.tenders.filter(tender => {
            return tender.id !== id;
          });
          this.$notify('Vous avez supprimé un document');
        })
        .catch(console.error)
        .finally(this.closeDeleteTenderModal);
    },
    clearQuery() {
      this.filters.query = ''
    },
    downloadTender(tender) {
      this.$axios.get(`/tenders/${tender.id}`, {
        headers: {
          Accept: 'application/vnd.productbot.original+octet-stream'
          }
      })
      .then((res) => {
        window.open(res.data.link, '_blank')
      })
      .catch(() => {
        this.$notify({
          text: 'Erreur lors du téléchargement',
          type: 'error',
        });
      });
    },
    exportTender(tender) {
      this.$axios.get(`/tenders/${tender.id}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", tender.name + '.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        this.$notify({
          text: 'Ce fichier n\'a pas pu être exporté',
          type: 'error',
        });
      });
    },
  }
};
</script>

<style scoped src="../assets/css/home.css"></style>
<style scoped>
.btn--blue {
  text-decoration: none;
}

.loading {
  display: flex;
  justify-content: center;
}
.loading .lds-dual-ring::after {
  border-color: black transparent black transparent
}
.lds-dual-ring {
  margin-bottom: 5px;
}

.datatable {
  margin: 0 80px 30px;
}

input.search-active {
  border: 1px solid #0F0455;
}
.search-cross {
  margin-left: -20px;
  cursor: pointer;
}
</style>
