<template>
  <!-- Start popup -->
  <div class="popup">
    <div class="popup__overlay"></div> <!-- Use it to detect a click outside of popup and close it -->

    <div class="popup__container">
      <button @click="close" class="btn--close"><img src="@/assets/images/icons/i-close.svg" align="close icon"></button>
      <h2 class="popup__title" >Suppression d'un document</h2>
      <p>Êtes-vous sûr de vouloir supprimer le document<br> <span class="bold">{{toDelete.name}}</span> de la liste ?</p>
      <button @click="confirmDeleteTender(toDelete.id)" class="btn btn--blue">Supprimer</button>
    </div>
  </div>
  <!-- End popup -->
</template>

<script>
  export default {
    name: 'DeleteDocument',
    props: ['toDelete', 'close', 'confirmDeleteTender'],

  };
</script>