<template>
  <!-- Start popup -->
  <div class="popup">
    <div class="popup__overlay"></div> <!-- Use it to detect a click outside of popup and close it -->

      <div class="popup__container">
      <button v-if="!loading" class="btn--close" @click="close()"><img src="@/assets/images/icons/i-close.svg" align="close icon"></button>
      <h2 class="popup__title">Importer un document</h2>

      <label class="input__file">
        <input ref="file" type="file" name="file" v-on:change="handleFileUpload()">
        <div>Choisir un document</div>
        <p v-if="file" class="filled" >{{file.name}}</p>
        <p v-else >Aucun document séléctionné</p>
      </label>

      <div class="option">
        <h3 class="option__title">Définir les plages de cellules souhaitées</h3>
        <div class="option__exception">
          <p>Choisir les colonnes de :</p>
          <div class="option__cellsContainer">
            <input v-uppercase v-model="columnsFrom" v-bind:class="{ 'option__cell--filled': columnsFrom }" type="text" placeholder="Ex : A" class="option__cell" >
            <p>à</p>
            <input v-uppercase v-model="columnsTo" v-bind:class="{ 'option__cell--filled': columnsTo }" type="text" placeholder="Ex : Z" class="option__cell">
          </div>
        </div>
        <button v-if="!displayColumnsException" @click="displayColumnsException = !displayColumnsException" class="btn--underlined"><img src="@/assets/images/icons/i-plus-black.svg" alt="plus icon"> Ajouter une exception </button>
        <div v-else class="option__exception">
          <p>Ne pas traiter les colonnes</p>
          <input v-uppercase v-model="columnsExcluded" v-bind:class="{ 'option__cell--filled': columnsExcluded }" type="text" placeholder="Ex : B,C,D" class="option__cell large">
        </div>
      </div>

      <div class="option">
        <h3 class="option__title">Définir les lignes de traitements souhaitées</h3>
        <div class="option__exception">
          <p>Ligne de début de traitement</p>
          <div class="option__cellsContainer">
            <input v-model="linesFrom" v-bind:class="{ 'option__cell--filled': linesFrom }" type="text" placeholder="Ex : 0" class="option__cell">
          </div>
        </div>
        <div class="option__exception">
          <p>Ligne de fin de traitement</p>
          <div class="option__cellsContainer">
            <input v-model="linesTo" v-bind:class="{ 'option__cell--filled': linesTo }" type="text" placeholder="Ex : 25" class="option__cell">
          </div>
        </div>
        <button v-if="!displayLinesException" @click="displayLinesException = !displayLinesException" class="btn--underlined"><img src="@/assets/images/icons/i-plus-black.svg" alt="plus icon"> Ajouter une exception </button>
        <div v-else class="option__exception">
          <p>Ne pas traiter les lignes</p>
          <input v-model="linesExcluded" v-bind:class="{ 'option__cell--filled': linesExcluded }" type="text" placeholder="Ex : 2,4,5" class="option__cell large">
        </div>
      </div>
      <button
        v-if="!loading"
        :disabled="file === null"
        @click="importTender"
        class="btn color--white"
        :class="{ 'btn--disabled': !file }">Importer un document</button>
      <button v-else class="btn btn--loading btn--disabled color--white"><span class="lds-dual-ring"></span></button>
    </div>
  </div>
  <!-- End popup -->
</template>

<script>
import ALL_ERRORS from '@/translations/errors';

export default {
  name: 'ImportDocument',
  props: ['close', 'addCreatedTender'],
  data: () => ({
    columnsFrom: '',
    columnsTo:'',
    columnsExcluded: '',
    linesFrom:'',
    linesTo:'',
    linesExcluded: '',
    file: null,
    displayColumnsException: false,
    displayLinesException: false,
    loading: false,
  }),
  methods: {
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    },
    importTender() {
      const { file, linesFrom, linesTo, linesExcluded, columnsFrom, columnsTo, columnsExcluded } = this;
      const data = new FormData()
      data.append('file', file)
      data.append('line', JSON.stringify({
        from: linesFrom,
        to: linesTo,
        excluded: linesExcluded,
      }))
      data.append('column', JSON.stringify({
        from: columnsFrom.toUpperCase(),
        to: columnsTo.toUpperCase(),
        excluded: columnsExcluded.toUpperCase(),
      }))

      this.loading = true

      this.$axios
        .post('/tenders', data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        this.addCreatedTender(response.data)
      })
      .catch((error) => {
        if (error.response.data && error.response.data.hasOwnProperty('errors')) {
          let foundError = false;
          error.response.data.errors.forEach(error => {
            if (ALL_ERRORS.hasOwnProperty(error.messageKey)) {
              foundError = true;
              this.$notify({
                text: ALL_ERRORS[error.messageKey],
                type: 'error',
              })
            }
          });
          if (!foundError) {
            this.$notify({
              text: `Une erreur s'est produite, l'appel d'offres n'a pas été importé.`,
              type: 'error',
            })
          }
        }
        else {
          this.$notify({
            text: `Une erreur s'est produite, l'appel d'offres n'a pas été importé.`,
            type: 'error',
          })
        }
        this.close()
      })
    },
  },
};
</script>

<style scoped>
@import '../assets/css/popup_import_document.css';
  .btn {
    background: #0B2956;
    border: none;
  }

  .btn--disabled {
    background: #C4C4C4;
  }

  .btn--loading {
    width: 200px;
    display: flex;
    justify-content: center;
  }

  .lds-dual-ring {
    margin-bottom: 4px;
    margin-top: -3px;
    margin-left: -7px;
  }
</style>
