<template>
  <!-- Start popup -->
  <div class="popup">
    <div class="popup__overlay"></div> <!-- Use it to detect a click outside of popup and close it -->

    <div class="popup__container">
      <button class="btn--close" @click="close()"><img src="@/assets/images/icons/i-close.svg" align="close icon"></button>
      <h2 class="popup__title">Importer un texte libre</h2>

      <form @submit.prevent>
        <input type="text" placeholder="Nom" v-model="title">
        <textarea v-model="text" placeholder="Insérer un texte libre"></textarea>
      </form>

      <button v-if="!loading" @click="importTender(text, title)" v-bind:class="{ 'btn--disabled': !text || !title }" class="btn color--white">Importer un texte libre</button>
      <button v-else class="btn btn--loading btn--disabled color--white"><span class="lds-dual-ring"></span></button>
    </div>
  </div>
  <!-- End popup -->
</template>

<script>
export default {
  name: 'ImportText',
  props: ['close', 'addCreatedTender'],
  data: () => ({
    loading: false,
    text: '',
    title: '',
  }),
  methods: {
    importTender(text, name) {
      if (!text || !name) {
        return;
      }

      this.loading = true

      this.$axios
        .post('/tenders', { text, name })
        .then(response => {
          this.addCreatedTender(response.data)
        })
        .catch((e) => {
          console.log('error', e)
          this.$notify({
            text: 'Une erreur s\'est produite, l\'appel d\'offres n\'a pas été importé.',
            type: 'error',
          })
          this.close()
        })
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/css/popup_import_document.css';

form {
  display: flex;
  flex-direction: column;

  input {
    border: 1px solid #C9C9C9;
    margin-bottom: 16px;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

.btn {
  background: #0B2956;
  border: none;
}

.btn--disabled {
  background: #C4C4C4;
}

.btn--loading {
  width: 200px;
  display: flex;
  justify-content: center;
}

.lds-dual-ring {
  margin-bottom: 4px;
  margin-top: -3px;
  margin-left: -7px;
}
</style>
