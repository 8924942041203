import { ref, watch, computed } from '@vue/composition-api';
import axios from '@/plugins/axios';

export default function usePaginate({
  url,
  currentPage: page = 1,
  updateFn = () => {},
  options = ref({}),
  dataTransformer = results => results,
  totalPageTransformer,
  totalTransformer,
  resultsPerPage = 25,
  range = 5,
}) {

  // Null at first, updated after the first call, according to the response pagination context
  const lastPage = ref(null);
  const total = ref(0);
  const loading = ref(false);

  const currentPage = ref(page);
  // Possibility to update the URL after changing the page for example
  watch(currentPage, (newValue) => {
    updateFn({ page: newValue })
  });

  const pages = computed(() => {
    const totalPages = (lastPage.value || 1);
    const paging = [];
    let start;

    if (!lastPage.value) {
      return [];
    } else if (currentPage.value >= (totalPages - (range / 2))) {
      start = Math.floor((totalPages - range < 1 ? 0 : totalPages - range) + 1);
    } else {
      start = currentPage.value - Math.floor(range / 2) < 1 ? 1 : currentPage.value - Math.floor(range / 2)
    }

    for (let i = start; i <= Math.min(lastPage.value, ((start + range) - 1)); i += 1) {
      paging.push(i);
    }

    if(!paging.includes(1)) {
      paging.unshift(1)
    }

    if(!paging.includes(totalPages)) {
      paging.push(totalPages)
    }

    return paging;
  });

  const data = ref([]);

  function call() {
    loading.value = true;
    return axios.get(url, {
      // Query parameters are merged with the default ones provided in the URL option
      headers: {
        Accept: 'application/json',
      },
      params: {
        limit: resultsPerPage,
        page: currentPage.value,
        ...options.value,
      },
    }).then(({ data: results }) => {
      data.value = dataTransformer(results);
      lastPage.value = Math.max(totalPageTransformer(results), 1);
      total.value = totalTransformer(results);

      // Recursively call this function if the page is out of range,
      // according to the response pagination context
      if (currentPage.value > lastPage.value && lastPage.value !== 0) {
        currentPage.value = lastPage.value;
        return call()
      }
      return results;
    })
      .finally(() => {
        loading.value = false
      });
  }

  function goToPage(pageNumber) {
    if (lastPage.value !== null) {
      currentPage.value = Math.min(Math.max(1, pageNumber), lastPage.value);
    }
    return call();
  }

  function previous() {
    currentPage.value = Math.max(1, currentPage.value - 1);
    return call();
  }

  function next() {
    currentPage.value = Math.min(lastPage.value || 1, currentPage.value + 1);
    return call();
  }


  return {
    currentPage,
    lastPage,
    loading,
    next,
    pages,
    goToPage,
    previous,
    data,
    total,
  };
}
